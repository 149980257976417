<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增':'修改'" :close-on-click-modal="false" @closed="cancel" width="480px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" v-if="form" :disabled="disabled">
        <el-form-item label="年度" prop="year">
          <el-date-picker v-model="form.year" type="year" value-format="yyyy" format="yyyy" :readonly="disabled" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="开始时间" prop="begTime">
          <el-date-picker v-model="form.begTime" type="datetime" value-format="timestamp" placeholder="开始日期" default-time="00:00:00" :readonly="disabled" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker v-model="form.endTime" type="datetime" value-format="timestamp" placeholder="结束日期" default-time="23:59:59" :readonly="disabled" style="width: 250px;" />
        </el-form-item>
        <el-form-item label="时段名称" prop="name">
          <el-input v-model.trim="form.name" style="width: 250px;" :maxlength="20" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/performanceDate";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        year: null,
        begTime: null,
        endTime: null,
        name: null,
      },
      rules: {
        name: [
          { required: "true", message: "时段名称为必填项", trigger: "blur" },
        ],
        year: [
          { required: "true", message: "年度为必填项", trigger: "change" },
        ],
        begTime: [
          { required: "true", message: "开始时间为必填项", trigger: "change" },
        ],
        endTime: [
          { required: "true", message: "结束时间为必填项", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          add(this.form)
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          edit(this.form)
            .then((res) => {
              this.dialog = false;
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500,
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch((err) => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      if (form) {
        this.form = form;
        this.form.year = form.year.toString();
      } else {
        this.form = {
          year: null,
          begTime: null,
          endTime: null,
          name: null,
        };
      }
    },
  },
};
</script>

